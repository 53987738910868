import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  // Para,
  Large,
  // Chat,
  // Empty,
  FooterContainer,
  // Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>

    <WhatsAppWidget
      companyName="Xhlar"
      replyTimeText="Normalmente responde en unas horas"
      sendButtonText="Enviar"
      inputPlaceHolder="Escribe tu mensaje"
      phoneNumber="+573232875931"
      message="Hola, ¿en qué puedo ayudarte?"
      size="20px"
      borderRadius="50%"
      backgroundColor="#25d366"
      position="bottom-left"
    />

      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={2} md={2} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Large left="true" to="/about">
                {t("About Us")}
              </Large>
              <Large left="true" to="/newsroom">
                {t("Newsroom")}
              </Large>
              <Large left="true" to="/partners">
                {t("Partners")}
              </Large>
              <Large left="true" to="/events">
                {t("Events")}
              </Large>
              <Large left="true" to="/careers">
                {t("Careers")}
              </Large>
              <Large left="true" to="/contact">
                {t("Contact Us")}
              </Large>
            </Col>
            <Col lg={2} md={2} sm={12} xs={12}>
              <Title>{t("PRODUCTS")}</Title>
              <Large left="true" to="/cloud">
                {t("Cloud Servers")}
              </Large>
              <Large left="true" to="/databases">
                {t("Managed Databases")}
              </Large>
              <Large left="true" to="/backup">
                {t("Simple Backup")}
              </Large>
            </Col>
            <Col lg={2} md={2} sm={12} xs={12}>
              <Title>{t("SOLUTIONS")}</Title>
              <Large left="true" to="/platforms">
                {t("Software and Platforms")}
              </Large>
              <Large left="true" to="/hosting">
                {t("Hosting Providers")}
              </Large>
              <Large left="true" to="/ecommerce">
                {t("eCommerce")}
              </Large>
            </Col>
            <Col lg={2} md={2} sm={12} xs={12}>
              <Title>{t("SUPPORT")}</Title>
              <Large left="true" to="/documentation">
                {t("Documentation")}
              </Large>
              <Large left="true" to="/tutorials">
                {t("Tutorials")}
              </Large>
              <Large left="true" to="/tools">
                {t("Tools")}
              </Large>
              <Large left="true" to="/faq">
                {t("FAQ")}
              </Large>
              <Large left="true" to="/help">
                {t("Help")}
              </Large>
              <Large left="true" to="/report-abuse">
                {t("Report Abuse")}
              </Large>
            </Col>
            <Col lg={2} md={2} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://github.com/xhlar"
                src="github.svg"
              />
              <SocialLink
                href="https://twitter.com/xhlar"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.linkedin.com/company/xhlar/"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://facebook.com/xhlarsas"
                src="facebook.svg"
              />
              <SocialLink
                href="https://instagram.com/xhlar/"
                src="instagram.svg"
              />
              <SocialLink
                href="https://youtube.com/@xhlar/"
                src="youtube.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
